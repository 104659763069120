import React from 'react'
import theme from '../styles/theme'
import Box from './primitives/Box'

type Option = {
  title: string
}

const ToggleSelector = <T extends Option>({
  options,
  selectedOption,
  onChange,
  variant = 'light',
}: {
  options: T[]
  selectedOption: T
  onChange: (option: T) => void
  variant?: 'light' | 'dark'
}) => {
  return (
    <Box
      width="100%"
      maxWidth={{ xs: '100%', lg: 400 }}
      display="flex"
      borderRadius={9999}
      css={`
        padding: 6px 9px;
        background: ${variant === 'light'
          ? 'linear-gradient(180deg, #f7f8fb 0%, #f0f2f6 100%)'
          : 'linear-gradient(180deg, #d9deed 0%, #eceef4 100%)'};
      `}
    >
      {options.map(option => {
        const isSelected = selectedOption.title === option.title
        return (
          <Box.button
            type="button"
            key={option.title}
            onClick={() => onChange(option)}
            flex={1}
            height={50}
            width={180}
            font="textLabel"
            borderRadius={9999}
            boxShadow={isSelected ? 'tiny' : 'none'}
            backgroundColor={isSelected ? 'white' : 'transparent'}
            css={`
              :hover {
                transform: none;
                background-color: ${isSelected
                  ? theme.colors.white
                  : 'transparent'};
              }
            `}
          >
            {option.title}
          </Box.button>
        )
      })}
    </Box>
  )
}

export default ToggleSelector
