import { Link } from 'gatsby'
import React from 'react'
import FAQWrapper from './FAQWrapper'

const FAQ = () => {
  return (
    <FAQWrapper>
      <details>
        <summary>How do I build a business case for Swarmia?</summary>
        <p>
          You can use the calculator on this page to get a conservative estimate
          of the value Swarmia can bring to your engineering organization. The
          numbers show that investing in Swarmia is a no-brainer for most
          organizations.
        </p>
        <p>
          You can also{' '}
          <Link to="/customers/">take a look at our customer case studies</Link>{' '}
          to find practical examples of the value individual customers have
          realized from adopting Swarmia.
        </p>
        <p>
          If you need help in making the case to your finance or leadership
          team, we’re happy to help. Just drop an email to{' '}
          <a href="mailto:hello@swarmia.com?subject=Help with building a business case for Swarmia">
            hello@swarmia.com
          </a>
          .
        </p>
      </details>
      <details>
        <summary>What are the numbers in the calculator based on?</summary>
        <p>
          The numbers in the calculator are based on our experience working with
          thousands of modern software engineering teams.
        </p>
        <p>
          <strong>
            The reference values are intentionally conservative, and they should
            be achievable to any organization using Swarmia.
          </strong>{' '}
          In fact, most organizations see much better results with Swarmia. For
          example, our customers often see 2-4x improvements in flow efficiency,
          20-50% improvements in cycle time, and a 10-20% increase in
          throughput.
        </p>
        <p>
          If you’re interested in real-life examples of the results some of our
          customers have seen, take a look at our case studies with{' '}
          <Link to="/story/factorial/">Factorial</Link>,{' '}
          <Link to="/story/gorgias/">Gorgias</Link>, and{' '}
          <Link to="/story/productsup/">ProductsUp</Link>.
        </p>
      </details>
      <details>
        <summary>Does the calculator account for all Swarmia features?</summary>
        <p>
          No, we’ve chosen a subset of Swarmia features for the calculator to
          make it work for most software organizations. We understand that the
          features you’ll find valuable depend on your organization’s unique
          context.
        </p>
        <p>
          If you’re interested in learning more about Swarmia features, you can{' '}
          <Link to="/product/">read more</Link> or{' '}
          <a
            href="https://www.youtube.com/watch?v=ZHcQO20bL4w"
            target="_blank"
            rel="noreferrer"
          >
            watch our product overview video
          </a>
          .
        </p>
      </details>
    </FAQWrapper>
  )
}

export default FAQ
