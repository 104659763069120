import React, { ReactNode, useRef, useState } from 'react'
import { useOnClickOutside } from '../hooks'
import Box, { BoxProps } from './primitives/Box'

type TooltipProps = {
  title?: string
  children: ReactNode
  font?: BoxProps['font']
  width?: BoxProps['width']
  tooltipTransform?: BoxProps['transform']
}

const TOOLTIP_WIDTH = 222

function Tooltip({
  title,
  children,
  font = 'smallLabel',
  width = TOOLTIP_WIDTH,
  tooltipTransform = 'translateX(-50%)',
}: TooltipProps) {
  const [isOpen, setIsOpen] = useState(false)
  const tooltipRef = useRef<HTMLDivElement>(null)
  useOnClickOutside(
    tooltipRef,
    () => {
      setIsOpen(false)
    },
    {
      skip: !isOpen,
    },
  )
  return (
    <Box
      ref={tooltipRef}
      position="relative"
      css={`
        &:hover > div {
          opacity: 1;
          pointer-events: auto;
        }
      `}
    >
      <Box.button
        onClick={() => setIsOpen(true)}
        backgroundColor="transparent"
        css={`
          :hover {
            background-color: transparent;
            transform: none;
          }
        `}
      >
        {children}
      </Box.button>
      <Box
        width={width}
        maxWidth="100vw"
        borderRadius={8}
        paddingY={8}
        paddingX={12}
        font={font}
        position="absolute"
        opacity={isOpen ? 1 : 0}
        pointerEvents={isOpen ? 'auto' : 'none'}
        top="100%"
        left="50%"
        transform={tooltipTransform}
        transition="opacity .1s ease-in"
        color="white"
        zIndex={1}
        css={`
          background-color: rgba(0, 0, 0, 0.9);
        `}
      >
        {title}
      </Box>
    </Box>
  )
}

export default Tooltip
